import React, {useState} from 'react';
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBIcon,
    MDBCheckbox
}
from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import httpClient from '../../config/httpClient';
import logo from '../../assets/images/logo.svg';
import { useAuth } from '../../hooks/useAuth';
import { emptyString, isValidEmail} from '../../helpers/validationHelpers';

export const UserRegistrationPage = () => {
    const auth = useAuth()

    const [email, setEmail] = useState("")
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [password, setPassword] = useState("")
    const [invalidPassword, setInvalidPassword] = useState(false)
    const[contactFN, setFN] = useState("")
    const [invalidContactFN, setInvalidContactFN] = useState(false);
    const[contactLN, setLN] = useState("")
    const [invalidContactLN, setInvalidContactLN] = useState(false);
    const [show, setShow] = useState(false);
    const navigate = useNavigate()
    
    const setInvalidations = async () => {
        if (
            emptyString(email) || emptyString(password) || emptyString(contactFN) || 
            emptyString(contactLN) || !isValidEmail(email)
            ) {
            
            setShow(true)
            if (!isValidEmail(email)) setInvalidEmail(true)
            if (emptyString(password)) setInvalidPassword(true)
            if (emptyString(contactFN)) setInvalidContactFN(true)
            if (emptyString(contactLN)) setInvalidContactLN(true)
            return true
        }
        return false;
    }

    const handleHide = () => {
        setInvalidContactFN(false);
        setInvalidContactLN(false);
        setInvalidEmail(false);
        setInvalidPassword(false);
        setShow(false);
        
    }

    const registerUser = async () => {
    if (!setInvalidations()) return
    try {
        const resp = await httpClient.post("https://api.sharpcloud.io/register/", {
        email,
        password,
        contactFN,
        contactLN,
        });
        if (resp.data.error) return
        const token = resp.data.token
        localStorage.setItem("token_user", token)
        auth.login(resp.data.token);
        httpClient.defaults.headers.common["x-access-tokens"] = token
        let billing = JSON.stringify(resp.data.url);
        console.log(resp.data.url)
        window.location = resp.data.url;
    } catch (error) {
        if (error.response.status === 401) {
        alert("Invalid credentials");
        }
    }
    };

    return(
        <MDBContainer fluid className='my-5'>

        <MDBRow className='g-0 align-items-center'>
          <MDBCol col='12'>
  
          <MDBCard className='my-5 mx-auto bg-glass' style={{borderRadius: '1rem', maxWidth: '400px', background: 'hsla(0, 0%, 100%, 0.55)'}}>
              <MDBCardBody className='p-5 shadow-5 text-center'>
  
              <div className='d-flex flex-row mt-2' style={{justifyContent : "center"}}>
                <img
                  src={logo}
                  width= "75%"type
                  margin= "auto"
                  display= "block"
                  className="d-inline-block align-top login-logo-size"
                  alt="Sharp Cloud Logo"
                />
              </div>
  
                <h2 className="fw-bold mb-5">Sign up now</h2>

                <MDBRow>
                    <MDBCol col='6'>
                        <MDBInput value = {contactFN} wrapperClass='mb-4' label='First name' id='form1' type='text' onChange={e => setFN(e.target.value)}/>
                    </MDBCol>

                    <MDBCol col='6'>
                        <MDBInput value = {contactLN} wrapperClass='mb-4' label='Last name' id='form2' type='text' onChange={e => setLN(e.target.value)}/>
                    </MDBCol>
                </MDBRow>
  
                <MDBInput value = {email} wrapperClass='mb-4' label='Email' id='form3' type='email' onChange={e => setEmail(e.target.value)}/>
                <MDBInput value = {password} wrapperClass='mb-4' label='Password' id='form4' type='password' onChange={e => setPassword(e.target.value)}/>
  
                <div className='d-flex justify-content-center mb-4'>
                  <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me on this device' />
                </div>
  
                <MDBBtn onClick={registerUser} className='w-100 mb-4' size='md' color='dark'>continue</MDBBtn>
  
                <div className="text-center">
  
                  <p>or sign up with:</p>
  
                  <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#000000' }}>
                    <MDBIcon fab icon='facebook-f' size="sm"/>
                  </MDBBtn>
  
                  <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#000000' }}>
                    <MDBIcon fab icon='twitter' size="sm"/>
                  </MDBBtn>
  
                  <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#000000' }}>
                    <MDBIcon fab icon='google' size="sm"/>
                  </MDBBtn>
  
                  <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#000000' }}>
                    <MDBIcon fab icon='github' size="sm"/>
                  </MDBBtn>
  
                  
  
                </div>
  
              </MDBCardBody>
            </MDBCard>
  
          </MDBCol>
        </MDBRow>
  
      </MDBContainer>
    )
}

export default UserRegistrationPage;