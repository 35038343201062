import React from "react";
import Routing from "../Routing";

export const App = () => {
    return(
       <>
        <Routing/>
       </>
    )
}

export default App;