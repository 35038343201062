import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "../../screens/Login";
import UserRegistration from "../../screens/UserRegistration";
import { AuthProvider } from "../../contexts/AuthContext";

export const routes = [
    {
        path: '/',
        name: 'Login',
        element: <LoginPage/>,
        withNav: false,
    },
    {
        path: '/login',
        name: 'Login',
        element: <LoginPage/>,
        withNav: false,
    },
    {
        path: '/register',
        name: 'Login',
        element: <UserRegistration/>,
        withNav: false,
    },



]

export const Routing = () => {
    return(
        <AuthProvider>
            <Routes>
                <Route>
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<UserRegistration />} />
                </Route>
            </Routes>
        </AuthProvider>
    )
}

export default Routing;