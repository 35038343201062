import React, {useState, createContext, useEffect} from 'react';
import httpClient from '../../config/httpClient';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const navigate = useNavigate()

    useEffect(() => { 
        if (!user)(async () => {
          try {
          const resp = await httpClient.get("https://api.sharpcloud.io/@me")
           setUser(resp.data.token)
           // navigate('/leadboard', {replace: true})
          } catch (error) {
            console.log("Not authenticated");
          }
        })();
      }, []);

    const login = (response) => {
        setUser(response)
    }

    const logout = () => {
        setUser(null)
    }

    return(
        <AuthContext.Provider value={{user,login,logout}}>
            {children}
        </AuthContext.Provider>
    )
}