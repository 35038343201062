import { useState, useEffect } from 'react';
import React from 'react';
import httpClient from '../../config/httpClient';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
  MDBCheckbox
}
from 'mdb-react-ui-kit';
import logo from '../../assets/images/logo.svg';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import "./Login.css";

const LoginPage = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const auth = useAuth()
  const navigate = useNavigate()

  const logInUser = async () => {
    try {
      const resp = await httpClient.post("https://api.sharpcloud.io/login/", {
        email,
        password,
      });
      if (resp.data.error) return
      const token = resp.data.token
      localStorage.setItem("token_user", token)
      auth.login(resp.data.token);
      httpClient.defaults.headers.common["x-access-tokens"] = token
      let billing = JSON.stringify(resp.data.url);
      console.log(billing)
      console.log(resp.data.url)
      window.location = resp.data.url;
    } catch (error) {
      if (error.response.status === 401) {
        alert("Invalid credentials");
      }
    }
  };

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();

        logInUser()
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [email, password])

  return (
    <MDBContainer fluid className='my-5'>

      <MDBRow className='g-0 align-items-center'>
        <MDBCol col='12'>

        <MDBCard className='my-5 mx-auto bg-glass' style={{borderRadius: '1rem', maxWidth: '400px', background: 'hsla(0, 0%, 100%, 0.55)'}}>
            <MDBCardBody className='p-5 shadow-5 text-center'>

            <div className='d-flex flex-row mt-2' style={{justifyContent : "center"}}>
              <img
                src={logo}
                width= "75%"type
                margin= "auto"
                display= "block"
                className="d-inline-block align-top login-logo-size"
                alt="Sharp Cloud Logo"
              />
            </div>

              <h2 className="fw-bold mb-5">Sign in</h2>

              <MDBInput wrapperClass='mb-4' label='Email' id='form3' type='email' onChange={e => setEmail(e.target.value)}/>
              <MDBInput wrapperClass='mb-4' label='Password' id='form4' type='password' onChange={e => setPassword(e.target.value)}/>

              <div className='d-flex justify-content-center mb-4'>
                <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me on this device' />
              </div>

              <MDBBtn onClick={logInUser} className='w-100 mb-4' size='md' color='dark'>continue</MDBBtn>

              <div className="text-center">

                <p>or sign in with:</p>

                <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#000000' }}>
                  <MDBIcon fab icon='facebook-f' size="sm"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#000000' }}>
                  <MDBIcon fab icon='twitter' size="sm"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#000000' }}>
                  <MDBIcon fab icon='google' size="sm"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#000000' }}>
                  <MDBIcon fab icon='github' size="sm"/>
                </MDBBtn>

                

              </div>

              <p className="mb-md-4 pb-lg-2" style={{color: '#000000'}}>Don't have an account? <Link to="/register" style={{color: '#000000'}}>Register here</Link></p>

            </MDBCardBody>
          </MDBCard>

        </MDBCol>
      </MDBRow>

    </MDBContainer>
  );

};

export default LoginPage;